<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Marketing</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Gift Cards">
								<ng-template matTabContent>
									<app-giftcards></app-giftcards>
								</ng-template>
							</mat-tab>
							<!--<mat-tab label="Free Gifts">
								<ng-template matTabContent>
								TBD
								</ng-template>
							</mat-tab>-->
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>