<ng-template #trackingModal let-modal class="dragmodal">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Assign Tracking Numbers</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class=modal-body>
		<app-order-tracking [forced_orderno]="trackingorder"></app-order-tracking>
	</div>
</ng-template>

<ng-template #priceoverideModal let-modal class="dragmodal">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Price Overide for Simple Order #{{overide_order.simple_order_id}}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class=modal-body>
		
	</div>
</ng-template>


<ng-template #detailsModal let-modal>
	<div class="modal-header">
		<div class="col-12 modal-title m-0 p-0">
			<!-- <h4 class="italics text-danger text-bolder">Price Overide</h4>
			<hr style="height: 1px" class="m-0 p-0"> -->
			<h4 class="modal-title" id="modal-basic-title">{{ orderdetails.header.name }} ( {{ orderdetails.header.debtorno}} ) </h4>
		</div>
		<button type="button" class="btn-close pos-abs" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row m-0 p-0 pb-1">
				<div class="col-12 m-0 p-0 d-flex justify-content-end mb-1" *ngIf="!hidetracking">
					<button class="justify-content-end" mat-raised-button color="primary" (click)="openTrackingAssign(orderdetails.salesorder.orderno)">
						<mat-icon>edit</mat-icon>Tracking
					</button>
				</div>
		</div>

		<div class="table table-responsive">
			<table class="table table-sm">
				<thead>
					<tr>
						<th colspan="3" *ngIf="!hidetracking">
							<h4>SIMPLE ORDER: <b>{{ orderdetails.header.orderno }}</b></h4>
						</th>
						<th colspan="5" *ngIf="hidetracking">
							<h4>SIMPLE ORDER: <b>{{ orderdetails.header.orderno }}</b></h4>
						</th>
						<th colspan="3" class="italics text-center bl-1 br-1" >
							Internal&nbsp; <span [ngClass]="orderdetails.header.internaltot > orderdetails.header.simpletot && orderdetails.header.internaltot != orderdetails.header.simpletot ? 'text-danger' : ''">{{orderdetails.header.internaltot | currency}}</span>
						</th>
						<th colspan="3" class="italics text-center bl-1 br-1">
							Simple&nbsp; <span [ngClass]="orderdetails.header.simpletot > orderdetails.header.internaltot ? 'text-success' : ''">{{orderdetails.header.simpletot | currency}}</span>
						</th>
						<th class="text-center align-middle ">
							<mat-icon *ngIf="orderdetails.header.simpletot < orderdetails.header.internaltot" class="text-danger">arrow_circle_down</mat-icon>
							<mat-icon *ngIf="orderdetails.header.simpletot > orderdetails.header.internaltot" class="text-success">arrow_circle_up</mat-icon>
						</th>
					</tr>
					<tr>
						<th class="text-left">ITEM</th>
						<th class="text-left">DESC</th>
						<th class="text-center" *ngIf="hidetracking">NEEDS PO</th>
						<th class="text-center" *ngIf="hidetracking">ORDERABLE</th>						
						<th class="text-center">QTY</th>
						<th class="text-center bl-1">PRICE</th>
						<th class="text-center">FET</th>
						<th class="text-center br-1">EXT.</th>
						<th class="text-center bl-1">PRICE</th>
						<th class="text-center">FET</th>
						<th class="text-center br-1">EXT.</th>
						<th class="text-center">Discrep?</th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let item of orderdetails.details">
						<tr>
							<td class="text-left"><b>{{ item.stkcode }}</b></td>
							<td>{{ item.description }}</td>
							<td class="text-center" [ngClass]="item.porequired ? 'po' : ''" *ngIf="hidetracking">
								<ng-container *ngIf="item.porequired">Y</ng-container>
								<ng-container *ngIf="!item.porequired">N</ng-container>
							</td>
							<td class="text-center" [ngClass]="item.porequired && item.canbeordered ? 'remote' : item.porequired && !item.canbeordered ? 'remote-err' : ''" *ngIf="hidetracking">
								<ng-container *ngIf="item.porequired && item.canbeordered">
									Y<br>
									from {{item.remotesupplier.supplierno}}
								</ng-container>
								<ng-container *ngIf="item.porequired && !item.canbeordered">
									N<br>
									{{item.error_reason}} for {{item.remotesupplier.supplierno}}
								</ng-container>
								<ng-container *ngIf="!item.porequired">
									<span class="">-</span> 
								</ng-container>
							</td>
							<td class="text-center">{{ item.ordered }} </td>
							<td class="text-center pl-1 pr-1 bl-1">{{ item.internalprice | currency}} </td>
							<td class="text-center pl-1 pr-1">{{ item.internal_fet | currency}} </td>
							<td class="text-center pl-1 pr-1 br-1">{{ financial(getExtension(item, 'internal')) | currency }} </td>
							
							<td class="text-center pl-1 pr-1 bl-1">{{ item.simpleprice | currency}} </td>
							<td class="text-center pl-1 pr-1">{{ item.fet | currency}} </td>
							<td class="text-center pl-1 pr-1 br-1">{{ financial(getExtension(item, 'simple')) | currency }} </td>
							
							<td [ngClass]="orderdetails.header.internaltot > orderdetails.header.simpletot ? 'bg-danger text-white text-center' : 'text-center bg-success text-white' ">
								{{ orderdetails.header.simpletot - orderdetails.header.internaltot | currency }} 
							</td>
						</tr>
						<tr *ngIf="altering">
							<td colspan="5"></td>
							<td colspan="3">
								<mat-form-field>
									<mat-label>Price Override</mat-label>
									<span matPrefix>$</span>
									<input matInput type="text" placeholder="Price" [value]="item.internalprice" (input)="updatePrice($event, item)" appNumericField>
								  </mat-form-field>
							</td>
							<td colspan="4"></td>
						</tr>
					</ng-container>
				</tbody>
			</table>
			<ng-container *ngIf="orderdetails.salesorder">
				<table class="table w-100">
					<thead>
						<tr>
							<th colspan="5" class="bt-1">
								<h4>INTERNAL SALES ORDER: <b>{{orderdetails.salesorder.orderno}}</b></h4>
							</th>
						</tr>
						<tr>
							<th>STOCKID</th>
							<th>DESC.</th>
							<th>QTY</th>
							<th>PRICE</th>
							<th>EXT</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of orderdetails.salesorder.details">
							<td>{{item.stkcode}}</td>
							<td>{{item.description}}</td>
							<td>{{item.ordered}}</td>
							<td>{{item.unitprice | currency}}</td>
							<td>{{financial(getExtension(item, 'so')) | currency}}</td>
						</tr>
					</tbody>
					<tr *ngIf="orderdetails.salesorder">
						<th colspan="4" class="text-right">TOTAL:</th>
						<th>{{financial(getSoTotal(orderdetails.salesorder.details)) | currency}}</th>
					</tr>
				</table>
			</ng-container>
		</div>

		<!-- <div class="row" *ngIf="overide_order">
			<div class="col-12 m-0 p-0 d-flex justify-content-end">
				<button mat-raised-button (click)="ov(orderdetails)">Process With Overides</button>
			</div>
		</div> -->

		<div class="row" *ngIf="showCancel">
			<div class="col-8"></div>
			<div class="col-auto">
				<mat-form-field class="full-width" appearance="outline">
					<mat-label>Canellation Reason</mat-label>
					<mat-select [formControl]="cancelReasonsRef">
						<mat-option *ngFor="let model of cancelReasons" [value]="model.id">
							{{ model.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<br>
			<button mat-raised-button  (click)="cancelSimpleOrder(orderdetails)" class="btn-danger export-btn" [disabled]="cancelReasonsRef.value == ''">Cancel Order</button>
		</div>

		<div class="row m-0 p-0 d-block" *ngIf="altering">
			<button mat-raised-button color="primary" (click)="modalService.dismissAll('overidePrice')" class="export-btn float-right">Process Order</button>
		</div>

	</div>
</ng-template>

<ng-template #purchaseOrderDetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" *ngIf="podetails">
			<a [routerLink]="'/vendors/'+podetails.poheader.supplierno">{{ podetails.poheader.supplierno }}</a> Purchase Order
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body">
		<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" class="no-overflow">
			<!-- Purchase Order Tab -->
			<mat-tab label="Purchase Order">
				<div class="mb-3" *ngIf="podetails">
					<h4 class="mt-3">PO #{{ podetails.poheader.orderno }}</h4>
					<div class="card-deck mt-3">
						<div class="card col-6">
							<div class="card-header">
								<strong>Contact Information</strong>
							</div>
							<div class="card-body">

								<p class="card-text">
									<strong>Contact:</strong> {{podetails.poheader.contact}}<br>
									<strong>Address:</strong>
									<ng-container *ngIf="podetails.address">
										<div [innerHTML]="podetails.address"></div>
									</ng-container>
								</p>
							</div>
						</div>
						<div class="card col-6">
							<div class="card-header">
								<strong>Delivery Information</strong>
							</div>
							<div class="card-body">
								<p class="card-text">
									<strong>Delivery Address:</strong><br>
									<ng-container *ngIf="podetails.poheader.deladd1">{{podetails.poheader.deladd1}}<br></ng-container>
									<ng-container *ngIf="podetails.poheader.deladd2">{{podetails.poheader.deladd2}}<br></ng-container>
									<ng-container *ngIf="podetails.poheader.deladd3">{{podetails.poheader.deladd3}},</ng-container>
									<ng-container *ngIf="podetails.poheader.deladd4">{{podetails.poheader.deladd4}}</ng-container>
									<ng-container *ngIf="podetails.poheader.deladd5">{{podetails.poheader.deladd5}}</ng-container>
								</p>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="card col-6">
							<div class="card-header">
								<strong>Notes</strong>
							</div>
							<div class="card-body" [innerHTML]="podetails.poheader.stat_comment"></div>
						</div>
						<div class="card col-6">
							<div class="card-header">
								<strong>Totals</strong>
							</div>
							<div class="card-body">
								<p class="card-text">
									<strong>Total Quantity:</strong> {{ getTotalQuantity() }}<br>
									<strong>Total Value:</strong> {{ getTotalValue() | currency }}<br>
								</p>
							</div>
						</div>
					</div>
					<div class="table-responsive mt-3">
						<table class="table table-bordered table-striped table-hover normal" #pdata>
							<thead class="thead-dark">
								<tr>
									<th>ITEM</th>
									<th>DESC</th>
									<th class="text-center">QTY</th>
									<th class="text-center">RCVD</th>
									<th class="text-center">PEND</th>
									<th class="text-right">PRICE</th>
									<th class="text-right">TOTAL</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of podetails.polines">
									<td>
										<button mat-icon-button (click)="copyToClipboard(item.itemcode)" class="ml-2" title="Copy to clipboard">
											<mat-icon>content_copy</mat-icon>
										</button>

										<a [routerLink]="'/inventory/view/'+item.itemcode" (click)="modal.dismiss('route change')">
											{{item.itemcode}}
										</a>

									</td>
									<td>{{item.itemdescription}}</td>
									<td class="text-center">{{item.quantityord}}</td>
									<td class="text-center">{{item.quantityrecd}}</td>
									<td class="text-center">{{item.quantityord - item.quantityrecd}}</td>
									<td class="text-right">{{item.unitprice | currency}}</td>
									<td class="text-right">{{item.unitprice * item.quantityord | currency}}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="2"><strong>Total</strong></td>
									<td class="text-center"><strong>{{ getTotalQuantity() }}</strong></td>
									<td colspan="3"></td>
									<td class="text-right"><strong>{{ getTotalValue() | currency }}</strong></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</mat-tab>




			<!-- Receiving Details Tab -->
			<mat-tab label="Receiving Details">
				<ng-container *ngIf="podetails.receiving.length">
					<table class="table table-fixed table-sm">
						<thead>
							<tr>
								<th>GRN</th>
								<th>Order#</th>
								<th>Item#</th>
								<th>Description</th>
								<th>Received</th>
								<th>Invoiced</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let r of podetails.receiving">
								<td>{{ r.grnno }}</td>
								<td>{{ r.poorderno }}</td>
								<td>{{ r.itemcode }}</td>
								<td>{{ r.itemdescription }}</td>
								<td>{{ r.qtyrecd }}</td>
								<td>{{ r.quantityinv }}</td>
								<td>{{ r.update_date }}</td>
							</tr>
						</tbody>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.receiving.length">
					<p>No Receiving Details</p>
				</ng-container>
			</mat-tab>
			<!-- Receiving Movements Tab -->
			<mat-tab label="Receiving Movements">
				<ng-container *ngIf="podetails.movements.length">
					<table class="table table-fixed table-sm">
						<thead>
							<tr>
								<th>Item#</th>
								<th>Location</th>
								<th>User</th>
								<th>Quantity</th>
								<th>QOH</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let r of podetails.movements">
								<td>{{ r.stockid }}</td>
								<td>{{ r.loccode }}</td>
								<td>{{ r.userid }}</td>
								<td>{{ r.qty }}</td>
								<td>{{ r.newqoh }}</td>
								<td>{{ r.trandate }}</td>
							</tr>
						</tbody>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.movements.length">
					<p>No Movements</p>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel')">Close</button>
	</div>
</ng-template>

<div class="main-content">
	<div class="card">
		<div class="card-header card-header-{{color}}-6 row">
			<i class="material-icons" (click)="back()">arrow_left</i>
			<h4>SimpleTire Order Processing</h4>
		</div>
		<div class="card-body">
			<div class="row m-0 p-0">
				<div class="col-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Search For Orders</mat-label>
						<button matPrefix mat-icon-button color="white" (click)="$event.stopPropagation(); ordsearch.setValue(''); filterOrders()">
							<mat-icon>clear</mat-icon>
						</button>
						<ng-container >
							<input tabindex="1" #itemsearch matInput value="" [formControl]="ordsearch" class="text-right" autofocus (keyup)="$event.stopPropagation();$event.preventDefault();filterOrders();" >
						</ng-container>
						<span matSuffix>
							<button #itemSearchButton tabindex="-1" mat-icon-button color="white" (click)="filterOrders()">
								<mat-icon>search</mat-icon>
							</button>
						</span>
					</mat-form-field>
				</div>
				<div class="col-2">
					<div class="row m-auto">
						<mat-slide-toggle [checked]="searchByItems" (change)="itemSearchToggle()">Search by item</mat-slide-toggle>
					</div>	
					<div class="row m-auto">
						<mat-slide-toggle [checked]="showAlreadyScheduled" (change)="mergeOpenOrders(true)" matTooltip="Orders that have been scheduled on SimpleTire but not in V8">Already Scheduled?
							<ng-container *ngIf="fetchingOrds">(
								<svg class="spinner" width="12px" height="12px" viewBox="0 0 66 66"xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
								)</ng-container>
							<ng-container *ngIf="!fetchingOrds">({{alreadyscheduled.length}})</ng-container>
						</mat-slide-toggle>
					</div>
				</div>
				<div class="col-4">
					<div class="row m-0 p-0 justify-content-end mr-2">
					<button mat-raised-button (click)="exportPdf()" [disabled]="!scheduledOrders.length" class="bg-danger text-white export-btn">PDF</button>
					<button mat-raised-button (click)="exportXls()" [disabled]="!scheduledOrders.length" class="bg-success text-white export-btn">XLSX</button>
						<button mat-raised-button (click)="getOrders()"  class="reload-btn" color="accent">
							<svg *ngIf="fetchingOrds" class="spinner" width="35px" height="35px" viewBox="0 0 66 66"xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
							<mat-icon *ngIf="!fetchingOrds">refresh</mat-icon>
						</button>
					</div>
				</div>
			</div>

			<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedIndex)="tabindex" (selectedTabChange)="tabindex = tabindex == 0 ? 1 : 0">
				
				<mat-tab label="New Orders ({{scheduledOrders.length}})">
					
					<table *ngIf="scheduledOrders.length && !fetchingOrds" class="table table-striped">
						<thead>
							<tr>
								<th>
									<button mat-stroked-button (click)="selectAllOrders();">Select all?</button>
								</th>
								<th>Status</th>
								<th>Simple Order ID</th>
								<th>Warehouse Order ID</th>
								<th>Required Ship Date</th>
								<th>Customer</th>
								<th>Address</th>
								<th>Internal SubTot</th>
								<th>Simple SubTot</th>
								<th>Discrep?</th>
								<th>Needs PO</th>
								<th>Orderable</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<ng-container *ngFor="let ord of scheduledOrders_filtered">
								<tr [ngClass]="stoplLoading || dragging ? 'no-events' : 'ord-row'" (mouseenter)="previewOrd = buildOrderDetails(ord)" (mouseleave)="previewOrd = false"> 
									<td class="vertical-align">
										<button mat-stroked-button [matMenuTriggerFor]="menu">
											<mat-icon>more_vert</mat-icon>
										</button>
										&nbsp;&nbsp;&nbsp; <!--MAT BUTTON GROUP NEEDED HERE FOR VIEW ITEMS, CANCEL ORDER WITH SIMPLE-->
										<mat-menu #menu="matMenu">
											<button mat-menu-item (click)="viewSimpleOrder(ord)"><mat-icon>visibility</mat-icon>View Order</button>
											<button mat-menu-item (click)="getLabel(ord)"><mat-icon>save</mat-icon>Download Labels</button>
											<button mat-menu-item (click)="getLabel(ord, 'print')"><mat-icon>print</mat-icon>Print Labels</button>	
											<button mat-menu-item (click)="process(ord, true)" *ngIf="!ord.canbeordered || ord.status == 'Scheduled'"><mat-icon>create</mat-icon>Create Salesorder Only</button>
											<button mat-menu-item (click)="alterPrice(ord)" *ngIf="!ord.canbeordered || ord.total < ord.internal_total"><mat-icon>create</mat-icon>Edit Internal Price & Process</button>
											<button mat-menu-item (click)="viewSimpleOrder(ord, true)"><mat-icon>cancel</mat-icon>Cancel SimpleTire</button>
										</mat-menu>
										<mat-checkbox class="example-margin" [checked]="ord.checked" (change)="ord.checked = ord.checked ? false : true" [disabled]="!ord.canbeordered || ord.total < ord.internal_total || ord.status == 'Scheduled'"></mat-checkbox>
									</td>
									<td *ngIf="ord.status != 'Scheduled'">{{ord.status}}</td>
									<td *ngIf="ord.status == 'Scheduled'" class="text-danger">
										<div>{{ord.status}}</div>
									</td>
									<td>
										<span>{{ord.simple_order_id}}</span>
									</td>
									<td>{{ord.warehouse_order_id}}</td>
									<td>{{ord.required_ship_date | date:'MM/d/y'}}</td>
									<td>{{ord.headers.customer_first_name}}&nbsp;{{ord.headers.customer_last_name}} </td>
									<td>{{ord.headers.shipping_address1}} {{ord.headers.shipping_address2}}, {{ord.headers.shipping_city}} {{ord.headers.shipping_state}}, {{ord.headers.shipping_zip}}</td>
									<td >{{ord.internal_total | currency}}</td>
									<td (click) ="ord.showlinedetails = true" (mouseleave)="ord.showlinedetails = false">{{ord.total | currency}}</td>
									<td>
										<span *ngIf="ord.total < ord.internal_total" class="discrepbad"> -{{ord.internal_total - ord.total | currency}} </span>
										<span *ngIf="ord.total > ord.internal_total" class="discrepgood"> +{{ord.total - ord.internal_total | currency}} </span>
										<span *ngIf="ord.total == ord.internal_total"></span>
									</td>
									<td>
										<ng-container  *ngIf="ord.porequired && ord.canbeordered"><span class="po">NEEDS PO</span></ng-container>
										<ng-container *ngIf="ord.porequired && !ord.canbeordered"><span class="remote-err">ERROR</span></ng-container>
										<ng-container *ngIf="!ord.porequired"><span class="stock">IN STOCK</span></ng-container>
									</td>
									<td class="text-center">
										<ng-container  *ngIf="ord.porequired && ord.canbeordered">Y</ng-container>
										<ng-container *ngIf="ord.porequired && !ord.canbeordered">N</ng-container>
										<ng-container *ngIf="!ord.porequired"></ng-container>
									</td>
									<td>
										<button mat-stroked-button (click)="processMult(ord)" [ngClass]="ord.checked ? 'bg-success text-white' : ''" [disabled]="!ord.canbeordered || ord.total < ord.internal_total || ord.status == 'Scheduled'">
											<ng-container *ngIf="!ord.processing">
												Process
											</ng-container> 
											<ng-container *ngIf="ord.processing">
												<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66"xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
											</ng-container>
										</button>
									</td>
									<td>
										<mat-icon *ngIf="((ord.porequired && !ord.canbeordered) || ord.internal_total > ord.total || ord.status == 'Scheduled')" class="text-warning text-center">error</mat-icon>
									</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</mat-tab>
				<mat-tab label="Processed ({{processedOrders.length}})">
					<table *ngIf="processedOrders.length && !fetchingOrds" class="table table-striped">
						<thead>
							<tr>
								<th>
									<button mat-stroked-button (click)="selectAllOrders('printing');">Select all?</button>&nbsp;&nbsp;Print Opt.
								</th>
								<th>Status</th>
								<th>Interal Order#</th>
								<th>Purchase Order#</th>
								<th>Warehouse Order ID</th>
								<th>Simple Order ID</th>
								<th>Required Ship Date</th>
								<th>Customer</th>
								<th>Address</th>
								<th>SubTotal</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<ng-container *ngFor="let ord of processedOrders_filtered">
								<tr *ngIf="ord.salesorder">
									<td class="row m-0 p-0">
										<div class="col-6 m-0 p-0">
											<button mat-stroked-button [matMenuTriggerFor]="menu2">
												<mat-icon *ngIf="!ord.processing">more_vert</mat-icon>
												<svg *ngIf="ord.processing" class="spinner" width="35px" height="35px" viewBox="0 0 66 66"xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
											</button> &nbsp;&nbsp;&nbsp;
											<mat-menu #menu2="matMenu">
												<button mat-menu-item (click)="loadOrder(ord)"><mat-icon class="align-middle viscol" >visibility</mat-icon>View Order</button>
												<button mat-menu-item (click)="getLabel(ord)"><mat-icon>save</mat-icon>Download Labels</button>
												<button mat-menu-item (click)="getLabel(ord, 'print')"><mat-icon>print</mat-icon>Print Labels</button>
												<button mat-menu-item (click)="unProcessOrder(ord)" *ngIf="user && user?.userid == 'PHIL'"><mat-icon>edit</mat-icon>Un-Process Order</button>
												<button mat-menu-item (click)="cancelSimpleOrder(ord)"><mat-icon>cancel</mat-icon>Cancel SimpleTire Order</button>
											</mat-menu>
										</div>
										<div class="col-6 m-0 p-0 d-grid text-left">
											<span>
												<mat-checkbox [checked]="ord.orderchecked" (change)="ord.orderchecked = ord.orderchecked ? false : true">&nbsp;Pick</mat-checkbox>
											</span>
											<span>
												<mat-checkbox [checked]="ord.labelchecked" (change)="ord.labelchecked = ord.labelchecked ? false : true">&nbsp;Label</mat-checkbox>
											</span>
										</div>
									</td>
									<td>{{ord.status}}</td>
									<td>
										<span (click)="loadOrder(ord)" class="link pointer">{{ord.salesorder.orderno}}</span>
									</td>
									<td>
										<span [ngClass]="{'po': ord.salesorder.haspo.length }" *ngFor="let p of ord.salesorder.haspo">
											<span (click)="viewPurchaseOrder(p.purchase_orderno)" class="pointer">{{ p.purchase_orderno }}</span>
										</span>
										<span *ngIf="ord.salesorder.suppliers" [ngClass]="{'po': ord.salesorder.haspo.length }"><br>{{ ord.salesorder.suppliers }}</span>
										<span *ngIf="ord.salesorder.needspo && !ord.salesorder.haspo?.length" class="bg-danger text-white">
											FORCED - NEEDS PO!
										</span>
									</td>
									<td>{{ord.warehouse_order_id}}</td>
									<td>{{ord.simple_order_id}}</td>
									<td>{{ord.required_ship_date | date:'MM/d/y'}}</td>
									<td>{{ord.headers.customer_first_name}}&nbsp;{{ord.headers.customer_last_name}} </td>
									<td>{{ord.headers.shipping_address1}} {{ord.headers.shipping_address2}}, {{ord.headers.shipping_city}} {{ord.headers.shipping_state}}, {{ord.headers.shipping_zip}}</td>
									<td>{{ord.salesorder.total | currency}}</td>
									<td>
										<button mat-stroked-button (click)="printMult(ord)" [ngClass]="(ord.labelchecked || ord.orderchecked) ? 'bg-success text-white' : ''" [disabled]="!ord.orderchecked && !ord.labelchecked">
											<ng-container *ngIf="!ord.processing">
												Print
											</ng-container> 
											<ng-container *ngIf="ord.processing">
												<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66"xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
											</ng-container>
										</button>	
									</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</mat-tab>
			  </mat-tab-group>
		</div>
	</div>
	<div cdkDrag *ngIf="scheduledOrders_filtered.length && tabindex != 1" [ngClass]="previewOrd ? 'preview-box m-auto' : 'preview-box-icon m-auto'" (cdkDragStarted)="dragging = true" (cdkDragReleased)="dragging = false" (mouseenter)="stopLoading = true" (mouseleave)="stopLoading = false">
		<div class="col-12 m-0 p-0" *ngIf="previewOrd">
			<table class="table table-sm">
				<thead>
					<tr>
						<th colspan="3" *ngIf="!hidetracking">
							<h4>SIMPLE ORDER: <b>{{ previewOrd.header.orderno }}</b></h4>
						</th>
						<th colspan="5" *ngIf="hidetracking">
							<h4>SIMPLE ORDER: <b>{{ previewOrd.header.orderno }}</b></h4>
						</th>
						<th colspan="3" class="italics text-center bl-1 br-1" >
							Internal&nbsp; <span [ngClass]="previewOrd.header.internaltot > previewOrd.header.simpletot && previewOrd.header.internaltot != previewOrd.header.simpletot ? 'text-danger' : ''">{{previewOrd.header.internaltot | currency}}</span>
						</th>
						<th colspan="3" class="italics text-center bl-1 br-1">
							Simple&nbsp; <span [ngClass]="previewOrd.header.simpletot > previewOrd.header.internaltot ? 'text-success' : ''">{{previewOrd.header.simpletot | currency}}</span>
						</th>
						<th class="text-center align-middle ">
							<mat-icon *ngIf="previewOrd.header.simpletot < previewOrd.header.internaltot" class="text-danger">arrow_circle_down</mat-icon>
							<mat-icon *ngIf="previewOrd.header.simpletot > previewOrd.header.internaltot" class="text-success">arrow_circle_up</mat-icon>
						</th>
					</tr>
					<tr>
						<th class="text-left">ITEM</th>
						<th class="text-left">DESC</th>
						<th class="text-center" *ngIf="hidetracking">NEEDS PO</th>
						<th class="text-center" *ngIf="hidetracking">ORDERABLE</th>						
						<th class="text-center">QTY</th>
						<th class="text-center bl-1">PRICE</th>
						<th class="text-center">FET</th>
						<th class="text-center br-1">EXT.</th>
						<th class="text-center bl-1">PRICE</th>
						<th class="text-center">FET</th>
						<th class="text-center br-1">EXT.</th>
						<th class="text-center">Discrep?</th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let item of previewOrd.details">
						<tr>
							<td class="text-left"><b>{{ item.stkcode }}</b></td>
							<td>{{ item.description }}</td>
							<td class="text-center" [ngClass]="item.porequired ? 'po' : ''" *ngIf="hidetracking">
								<ng-container *ngIf="item.porequired">Y</ng-container>
								<ng-container *ngIf="!item.porequired">N</ng-container>
							</td>
							<td class="text-center" [ngClass]="item.porequired && item.canbeordered ? 'remote' : item.porequired && !item.canbeordered ? 'remote-err' : ''" *ngIf="hidetracking">
								<ng-container *ngIf="item.porequired && item.canbeordered">
									Y ({{item.remotesupplier.supplierno}})
								</ng-container>
								<ng-container *ngIf="item.porequired && !item.canbeordered">
									{{item.error_reason}} ({{item.remotesupplier.supplierno}})
								</ng-container>
								<ng-container *ngIf="!item.porequired">
									<span class="">-</span> 
								</ng-container>
							</td>
							<td class="text-center">{{ item.ordered }} </td>
							<td class="text-center pl-1 pr-1 bl-1">{{ item.internalprice | currency}} </td>
							<td class="text-center pl-1 pr-1">{{ item.internal_fet | currency}} </td>
							<td class="text-center pl-1 pr-1 br-1">{{ financial(getExtension(item, 'internal')) | currency }} </td>
							
							<td class="text-center pl-1 pr-1 bl-1">{{ item.simpleprice | currency}} </td>
							<td class="text-center pl-1 pr-1">{{ item.fet | currency}} </td>
							<td class="text-center pl-1 pr-1 br-1">{{ financial(getExtension(item, 'simple')) | currency }} </td>
							
							<td [ngClass]="previewOrd.header.internaltot > previewOrd.header.simpletot ? 'bg-danger text-white text-center' : 'text-center bg-success text-white' ">
								{{ previewOrd.header.simpletot - previewOrd.header.internaltot | currency }} 
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
		<div *ngIf="!previewOrd" class="text-center">
			<p class="pos-abs italics font-weight-lighter d-none">Preview Window</p>
			<mat-icon>preview</mat-icon>
		</div>
	</div>
</div>