import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, Pipe, PipeTransform, EventEmitter, ViewEncapsulation, NgZone } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, DatePipe } from '@angular/common'
import { Subject, Observable, Subscription, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PaymentsService } from '../../services/payments.service';
import { OmsService } from '../../services/oms.service';
import { DispatchService } from 'app/services/dispatch.service';

@Component({
	selector: 'app-shared-order-entry',
	templateUrl: './shared-order-entry.component.html',
	styleUrls: ['./shared-order-entry.component.scss'],
	//encapsulation: ViewEncapsulation.Emulated,
	//encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('300ms ease-in')),
			transition('active => inactive', animate('300ms ease-out')),
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('navIn', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.10s cubic-bezier(0.59, 0.32, 0.38, 0.33)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.0s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],
})
export class SharedOrderEntryComponent implements OnInit {

	@Input() customer: any = false;
	@Input() flip: any = 'inactive';
	@Input() type: any = '10';
	@Input() invoicing: any = false;
	@Output() reloadCustomer = new EventEmitter < any > ();
	@Output() customer_updated = new EventEmitter < any > ();

	changeCustomerInput = new UntypedFormControl('');

	CHAT_ROOM = 'OrderBoard';
	sending = false
	config: any = [];
	user: any = [];
	cart_items: any = [];
	proceed: any = true;
	total_cart_qty = 0;
	cart_totals: any = [];

	overcredit: any = false;
	allowed_credit: any = true;
	overcredit_override: any = true;
	editing_order: any = false;
	order_details: any = false;

	//orderResults: any = false;
	customer_form: any = false;
	vehicle_form: any = false;

	po_vendors: any = [];
	order: any = false;
	created_pos: any = false;
	extra_pos: any = false;
	order_lines: any = false;
	loading_remote = false;
	orderno: any = false;
	remoteqtys: any = [];

	purchase_item: any = false;
	purchordercreated_details: any = false;
	purchasedetails: any = false;
	purchordercreated: any = false;

	baselink: any = '';
	pickingInvoiceLink: any = '';

	orderDetails: any = false;
	orderResults: any = false;
	invoiceResults: any = false;
	inventorysearching: any = false;
	swapitems: any = false;

	anydata = {
		'success': true,
		'orderno': '754076',
		'transno': null,
		'order': '754076',
		'id': null
	};

	adjustment = {
		'additionalcharge': 0.00,
		'additionaltext': ''
	};
	freight_charge: any = 0.00;

	fetchingTotals: any = false;
	fetchingCart: any = false;
	purchase_items: any = [];
	customerdata: any = [];
	//deposits

	payments: any = [];
	payments_added: any = [];
	payment_total = 0.00;
	loadingcards = false;

	preorderDate: any = {
		status: true,
		value: new Date(),
	};

	editsallowed: any = {
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}

	tagalongs: any = false;

	issalesman: boolean = false;
	custNotes: any = [];
	totalsshowspinner: any = false;
	worker: Worker;
	@ViewChild("preorderdetails") preorderRef: ElementRef;
	@ViewChild("changeCustomer") changeCustomerRef: ElementRef;
	@ViewChild('assignTracking') assignTrackingRef: ElementRef;
	customerNotesSubscription: Subscription | null = null;
	

	constructor(private ngZone: NgZone, private dispatchService: DispatchService, private purchasingService: PurchasingService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService, private paymentsService: PaymentsService) {

		//private globalsService: GlobalsService,
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.reloadorder.subscribe(r => {
			if (r) {
				this.resetOrder();
			}
		});

		this.baselink = this.config.apiServer.baseUrl + this.config.apiServer.pickingLink;
		this.pickingInvoiceLink = this.config.apiServer.baseUrl + this.config.apiServer.pickingInvoice;

		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;

				if (results.user.issalesman && this.config.env.package == 'beauty') {
					this.issalesman = true;
					this.editsallowed = this.config.salesmanAllowed
				}
			}
		});

		this.inventoryService.getTagAlongs().subscribe((res) => {
			this.tagalongs = res;
		})

		if (typeof Worker !== 'undefined') {
			this.worker = new Worker(new URL('../../shared-order-entry-totals.worker', import.meta.url), {
				type: 'module',
			});

			this.worker.onmessage = ({ data }) => {
				this.ngZone.run(() => {
					if (data.error) {
						console.error(data.error);
					} else {
						this.cart_totals = data.results;
						this.total_cart_qty = data.total_cart_qty;

						if (!isNaN(this.total_cart_qty)) {
							const hasItems = Array.isArray(this.cart_items) && this.cart_items.length > 0;
							this.flip = this.total_cart_qty !== 0 || hasItems ? this.flip : 'inactive';
						}
						this.checkCreditLimit();
						this.totalsshowspinner = false;
					}
				});
			};
		}
	}

	showInvoiceResultandReset(event: any) {
		//this.invoiceResults = event;
		this.globalSearchService.showNotification('Invoice #' + event.transno + ' Created', 'success', 'bottom', 'left');
		this.resetOrder();
		this.router.navigate(['/orders/success/' + event.id]);
	}

	resetOrder() {

		this.invoicing = false;
		this.sending = false;
		this.orderResults = false;
		this.order_details = false;
		this.editing_order = false;
		this.customer.preselected = false;
		this.customer.dissallowinvoices = false;
		this.clearCart();
		this.updateTotals();

		this.reloadCustomer.emit('cleared');

	}

	updateShipping(value: any) {
		this.freight_charge = value
		this.updateTotals();
	}

	updateAdjust(value: any) {
		this.adjustment.additionalcharge = value.additionalcharge;
		this.adjustment.additionaltext = value.additionaltext;
	}

	updateCustomer(event: any) {
		this.customer_form = event
	}

	updateVehicle(event:any){
		this.vehicle_form = event;
	}

	updatePrint(event: any) {
		this.customer_form.doNotPrint = event;
	}
	invoiceOrder(orderno: any) {
		this.invoicing = orderno;
		this.orderResults = false;
	}

	addPromo(promo: any) {
		this.orderService.addPromo(promo, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			if (results.success) {

				this.cart_items = results.cart;
				this.updateTotals();
				this.globalSearchService.showNotification(results.message, 'success', 'bottom', 'right')

			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
			}
		});

	}

	addEdu(edu: any) {
		this.orderService.addEdu(edu, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			if (results.success) {

				this.cart_items = results.cart;
				this.updateTotals();
				this.globalSearchService.showNotification(results.message, 'success', 'bottom', 'right')

			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
			}
		});
	}



	getLineTotal(item: any): number {
		let qty = parseFloat(item.quantity)
		if (!qty || item.quantity == 0) {
			qty = 1;
		}

		if (this.config.env.package === 'beauty') {
			var value = this.financial(qty) * (this.financial(item.price) - this.financial(this.getItemDiscountMultiplier(item) * item.price))
		} else {
			//removed discount - handled on php side in $this->shop->getAlternateCustPrice
			var value = (this.financial(qty) * (this.financial(item.price)) + this.financial(item.fetvalue))
		}

		return value;
	}

	async updateTotals () {
		// Unsubscribe from any previous fetch if it's still ongoing
		if (this.fetchingTotals) {
			this.fetchingTotals.unsubscribe();
		}

		try {
			this.totalsshowspinner = true;

			// Make the HTTP call to getTotals in the main thread
			const results = await this.orderService.getTotals(this.customer.debtorno, this.customer.branchcode, this.freight_charge).toPromise();

			// Send data to the worker for further processing
			this.worker.postMessage({
				action: 'calculateTotals',
				payload: {
					results: results,
					cart_items: this.cart_items
				}
			});
		} catch (error) {
			// Make sure to update the spinner within Angular's zone even on error
			this.ngZone.run(() => {
				this.totalsshowspinner = false;
			});
		}
	}


	async addItems (items: any) {
		try {
			// Await the async call to addMultipleToOrder
			const results: any = await this.orderService.addMultipleToOrder(items, this.customer.debtorno, this.customer.branchcode).toPromise();

			if (results.success) {
				this.ngZone.runOutsideAngular(() => {
					this.cart_items = results.cart;

					// // Process each item outside Angular's zone
					// items.forEach((item) => {
					// 	if (parseFloat(item.quantity) != 0) {
					// 		const color = item.isnegative ? 'warn' : 'success';
					// 		this.ngZone.run(() => {
					// 			this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Added', color, 'bottom', 'left');
					// 		});
					// 	}
					// });

					// Update totals, run within Angular's zone since updateTotals contains UI updates
					this.ngZone.run(() => {
						this.updateTotals();
					});
				});
			} else {
				// UI update needs to be inside Angular's zone
				this.ngZone.run(() => {
					this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
				});
			}

		} catch (error) {
			//console.error('Error adding items to order:', error);
			this.ngZone.run(() => {
				this.globalSearchService.showNotification('Failed to add items to order.', 'danger', 'bottom', 'left');
			});
		}
	}

	async removeFromOrder (cart_id: string): Promise < void > {
		try {
			// Optimistically remove the item from the cart before sending the request
			this.cart_items = this.cart_items.filter(item => item.cart_id !== cart_id);

			// Send the request to remove the item from the order
			const results = await this.orderService.removeFromOrder(cart_id, this.customer.debtorno, this.customer.branchcode).toPromise();

			// Update the cart items with the result from the service, if necessary
			this.cart_items = results;

			// Show notification
			this.globalSearchService.showNotification('Item Removed', 'danger', 'bottom', 'left');

			// Update totals
			this.updateTotals();

			// Send cart update
			this.sendCartUpdate();
		} catch (error) {
			// Handle errors here if needed
			console.error('Error removing item from order:', error);
		}
	}


	updatePrice(event: any, item: any) {
		if (event.target.value != '') {
			const index = this.cart_items.indexOf(item);
			const newPrice = parseFloat(event.target.value);
			if (!isNaN(newPrice)) {
				this.cart_items[index].price = newPrice;
				this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
				this.updateCartItem(this.cart_items[index]);
			} else {
				this.globalSearchService.showNotification('Invalid price input', 'error', 'bottom', 'left');
			}
		}
	}

	updateQuantity(event: any, item: any) {
		if (event.target.value != '') {
			const index = this.cart_items.indexOf(item);
			const newQuantity = parseFloat(event.target.value);
			if (!isNaN(newQuantity)) {
				this.cart_items[index].quantity = newQuantity;
				this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
				this.updateCartItem(this.cart_items[index]);
			} else {
				this.globalSearchService.showNotification('Invalid quantity input', 'error', 'bottom', 'left');
			}
		}
	}

	updateDiscount(event: any, item: any) {

		if (event.target.value != '') {
			const index = this.cart_items.indexOf(item);

			if (parseFloat(event.target.value) < 100) {} else {
				event.target.value = 100;
			}

			this.cart_items[index].discount = event.target.value;
			this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
			this.updateCartItem(this.cart_items[index]);
		}

	}

	updateNote(event: any, item: any) {
		const index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	getDiscounPrice(item: any) {
		const total = (item.price - (item.price * (item.discount / 100)));
		// if(total < 0) {
		// 	total = 0;
		// }
		return total;
	}

	getDiscountTotal(item: any) {
		const total = item.quantity * (item.price - (item.price * (item.discount / 100)));
		// if(total < 0) {
		// 	total = 0;
		// }
		return total;
	}

	updateCartItem(item: any) {

		const data = {
			customer: this.customer.debtorno,
			branch: this.customer.branchcode,
			cartid: item.cart_id,
			cart_id: item.cart_id,
			price: item.price,
			option: item.option,
			quantity: item.quantity,
			discount: item.discount,
			notes: item.notes,
			stockid: item.stockid,
			coupon_used: item.coupon_used,
			editing_order: this.editing_order,
			order_details: this.order_details,
			taxoveride: item.taxdetails,
		};

		this.orderService.updateOrder(data).subscribe((results: any) => {
			//holdover for multiplier
			this.cart_items = results;
			if (this.config.env.package === 'tires') {
				this.updateChildren();
			}

			this.updateTotals();
		});
	}

	//if input is not actually the child? cartid: any not sure this is appropriate..
	updateChildren() {
		this.cart_items.forEach((item: any) => {
			if (item.parent_id) {
				const parent = this.cart_items.find(i => i.cart_id === item.parent_id);
				if (parent) {
					item.quantity = parent.quantity;
				}
			}
		});
	}

	cartQtyReducer() {
		var tagalongs = this.tagalongs

		function reducer (accumulator: number, items: any) {
			let counter = 0;
			if (items.mbflag != 'F' && !(tagalongs).includes(items.stockid)) {
				counter = parseFloat(items.quantity);
			}
			return accumulator + counter;
		}
		return reducer
	}

	getTotal() {

		const total = this.cart_totals.filter(t => {
			return t.code == 'total';
		})[0]

		let value = 0.00;
		if (total) {
			value = parseFloat(parseFloat(total.text).toFixed(2))
		}

		return value;

	}

	checkCreditLimit() {
		if (!this.allowed_credit) {
			this.proceed = true;
			this.overcredit = false;
			const subtotal = this.cart_totals.filter(t => {
				return t.code == 'sub_total'
			})[0]

			if (parseFloat(subtotal.text) > this.customer.credit_avail) {
				this.proceed = false;
				this.overcredit = true;
				if (this.overcredit_override == '') {
					this.orderService.flagCart(this.customer.debtorno, this.customer.branchcode).subscribe((result: any) => {
						this.overcredit_override = result
					})
				}
			}
		}
	}

	stopProp(event: any): void {
		event.stopPropagation();
	}

	sendCartUpdate() {

		if (this.user) {
			const data = [{ customer: this.customer.debtorno, user: this.user }]
			this.omsService.sendCartUpdate({ data, roomName: 'CartRoom' }, cb => {});
		}
	}

	toggleFlip() {

		switch (this.flip) {
			case 'inactive':
				this.flip = 'active';
				break;
			default:
				this.flip = 'inactive';
				break;
		}
	}

	ngOnInit(): void {
		this.getCustomerNotes()
	}

	ngOnChanges(changes: any) {
		if (changes && changes.customer && !this.custNotes.length) {
			this.getCustomerNotes()
		}

		this.updateCart();
	}

	emitCustomerUpdate(event: any) {
		this.invoicing = false;
		this.reloadCustomer.emit(event);
	}

	saveQuote() {

		if (this.editing_order) {
			this.editing_order = true;
			const continueconfirm = confirm('This is an open order, save as a quote and cancel order?');
			if (continueconfirm) {
				this.orderService.cancelOrder(this.order_details.header.orderno).subscribe((results: any) => {});
			} else {
				return false;
			}
		}

		this.orderService.saveQuote(this.customer, this.cart_items, this.cart_totals, this.user, this.customer_form.value).subscribe((result: any) => {
			this.globalSearchService.showNotification('Quote ' + result.orderno + ' Saved', 'success', 'bottom', 'left');
			this.reloadCustomer.emit(true)
			this.flip = 'inactive';
			this.orderDetails = false;
			this.resetOrder();
		});
	}

	clearCart() {
		this.orderService.clearOrder(this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			this.flip = 'inactive';
			this.updateCart();
		});
	}

	cancelOrder() {

		this.orderService.cancelOrder(this.order_details.header.orderno).subscribe((results: any) => {
			this.flip = 'inactive';
			this.invoicing = false;
			this.sending = false;
			this.orderResults = false;
			this.order_details = false;
			this.editing_order = false;
			this.customer.preselected = false;
			this.customer.dissallowinvoices = false;
			this.resetOrder();
			this.updateCart();
			//update sends
			const data = { user: this.user, }
			this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});


		});
	}

	placeOrder() {


		//this.checkCredit();
		this.sending = true;
		this.globalSearchService.getFormValidationErrors(this.customer_form);
		this.customer_form.markAllAsTouched();

		this.allowed_credit = true;
		/*
		if (!this.proceed && !this.allowed_credit) {
			this.modalService.open(this.creditover, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {
				this.item_details = false;
			});
		}
		*/
		//customer form is emitted in
		if (this.customer_form.valid && this.proceed) {

			if (!this.editing_order) {
				
				let vform = this.vehicle_form?.value ?? {};
				
				this.orderService.createOrder(this.customer_form.value, this.cart_items, this.cart_totals, this.user, this.adjustment, vform).subscribe((results: any) => {
						this.sending = false;
						//this.globalSearchService.orderResults.next(results);
						this.orderResults = results;
						const order_results = results;
						if (!results.success) {
							this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'right');
						}


						this.flip = 'inactive';
						this.orderDetails = results;
						this.reloadCustomer.emit(results);
						this.editing_order = false;
						this.customer.preselected = false;
						this.orderService.clearOrder(this.customer.debtorno, this.customer.branchcode).subscribe((r: any) => {
							this.cart_items = [];
							this.total_cart_qty = 0;
							const data = { customerdata: this.customer_form.value, editing: false, neworder: true, orderdetails: order_results, user: this.user };
							this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
							//this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
						});
					},
					(error: any) => {
						this.sending = false;
						console.error('Error creating order:', error);
					});

			} else { 

				const sendOrderPickUpdate$ = (data: any, roomName: string): Observable < any > => {
					return new Observable(observer => {
						this.omsService.sendOrderPickUpdate({ data, roomName }, (response: any) => {
							observer.next(response);
							observer.complete();
						});
					});
				};

				this.sending = true;

				// Update the order and process everything within a single subscription
				
				let vform = this.vehicle_form?.value ?? {};
				
				this.orderService.updateSalesOrder(
				  this.customer_form.value,
				  this.cart_items,
				  this.cart_totals,
				  this.order_details,
				  this.user,
				  this.adjustment,
				  vform
				).subscribe(
					(results: any) => {
						this.sending = false;

						if (!results.success) {
							this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'right');
							return;
						}

						this.flip = 'inactive';
						this.orderResults = results;
						this.orderDetails = results;
						this.reloadCustomer.emit(results);

						// Clear the cart and reset order state
						forkJoin({
							clearOrder: this.orderService.clearOrder(this.customer.debtorno, this.customer.branchcode),
							orderPickUpdate: sendOrderPickUpdate$(
								{
									customerdata: this.customer_form.value,
									editing: true,
									neworder: false,
									orderdetails: results,
									user: this.user
								},
								this.CHAT_ROOM
							)
						}).subscribe(
							({ clearOrder, orderPickUpdate }) => {
								this.cart_items = [];
								this.total_cart_qty = 0;
								this.editing_order = false;
								this.customer.preselected = false;
								this.customer.editingorder = false;

								if (this.config.scantotruck) {
									// Check if the order is part of active dispatch for a bay
									this.dispatchService.orderIsBeingLoaded({ orderno: results.orderno }).subscribe((r) => {
										if (r.dispatched) {
											sendOrderPickUpdate$({ customerdata: this.customer_form.value, orderdetails: results },
												this.CHAT_ROOM
											).subscribe();
										}
									});
								}
							},
							(error) => {
								console.error('Error processing order updates:', error);
							}
						);
					},
					(error: any) => {
						this.sending = false;
						console.error('Error updating order:', error);
					}
				);

			}
		} else {
			this.sending = false;
		}

		// this.updateCart()
	}




	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		const data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result: any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}



	itemSearch(item): any {
		const data = {
			keywords: item.inputValue,
			limit: 10,
			sort: 'SKU|ASC',
			customer: this.customer,
			filters: [],
			customer_items_only: false,
			vendor_items_only: false,
			type: '30',
			user: this.user.user
		};

		if (this.inventorysearching) {
			this.inventorysearching.unsubscribe();
		}

		this.inventorysearching = this.orderService.getItemSearch(data).subscribe((items: any) => {
			this.swapitems = items;
		});
	}

	selectSwapItem(item: any, fromitem: any) {
		let data = {
			cartid: fromitem.cartid,
			stockid: item.stockid,
			desription: item.description,
			price: item.price,
			quantity: fromitem.quantity
		}

		let updateitem = fromitem;

		updateitem.description = item.description;
		updateitem.stockid = item.stockid;
		updateitem.price = item.price;
		updateitem.quantity = fromitem.quantity;

		fromitem.inputValue = '';
		item.inputValue = '';
		item.switching = false;

		this.orderService.swapItem(data).subscribe((items: any) => {
			this.swapitems = false;
			this.updateCart(false);
		});

	}


	updateCart(inputflip = true) {
		if (this.fetchingCart) {
			this.fetchingCart.unsubscribe();
		}

		if (this.customer.debtorno) {
			this.fetchingCart = this.orderService.getCart(this.customer.debtorno, this.customer.branchcode).subscribe((items: any) => {

				const cart_items = items;
				if (inputflip) {
					if (items.length) {
						this.flip = 'active';
					}
				}
				//current cart tied to a sales order - user loaded an order and left
				const hasorderno = items.map(item => { return (item.orderno != 0) ? item.orderno : false })[0];

				//todo rework this - doubles up on the calls
				if (hasorderno && !this.order_details.header) {

					this.orderService.isPreOrder(hasorderno).subscribe((r: any) => {
		
					if(r.ispo){
					this.clearCart();
					this.orderService.loadPreOrder(hasorderno, false, false).subscribe((results: any) => {
					const { header, preselected, cart } = results;

						// Set editing_order and customer.editingorder
						this.editing_order = true;
						this.customer.editingorder = header;

						// Set preselected and order_details
						this.customer.preselected = preselected;
						this.order_details = results;
						this.adjustment.additionalcharge = this.order_details.header.additionalcharge;
						this.adjustment.additionaltext = this.order_details.header.additionaltext;

						// Set cart_items
						this.cart_items = cart;

						// Update flip based on cart length
						this.flip = cart.length > 0 ? 'active' : '';

						// Update payments
						this.updatePayments(false);
					});
					} else {

					this.orderService.loadSalesOrder(hasorderno).subscribe((results: any) => {
						const { header, preselected, cart } = results;

						// Only proceed if header is valid
						if (header) {
							// Set editing_order and customer.editingorder
							this.editing_order = true;
							this.customer.editingorder = header;

							// Set preselected and order_details
							this.customer.preselected = preselected;
							this.order_details = results;

							//set vehicle form
							this.vehicle_form = header.vehicle;


							// Set adjustment fields
							const { additionalcharge, additionaltext } = header;
							this.adjustment = { additionalcharge, additionaltext };
							
							// Set cart_items
							this.cart_items = cart;

							// Update flip based on cart length
							this.flip = cart.length > 0 ? 'active' : '';

							// Update payments
							this.updatePayments(false);
						}
					});

					}

					});

				} else {
					this.cart_items = items;
					this.adjustment.additionalcharge = 0.00;
					this.adjustment.additionaltext = '';
					const fromquote = items.map((i) => { return i.quoteno })[0];

					if (fromquote !== '' && fromquote && fromquote !== 0 && fromquote !== '0') {

						this.orderService.loadQuote(fromquote).subscribe((r: any) => {
							const header = r.header;
							if (header) {
								const { shipvia, freightcost, comments, notes } = header;
								const defaultShipViaControl = this.customer_form.get('defaultshipvia');
								const freightCostControl = this.customer_form.get('freightcost');
								const commentsControl = this.customer_form.get('comments');
								const notesControl = this.customer_form.get('notes');
								const refControl = this.customer_form.get('reference');


								if (defaultShipViaControl && shipvia) {
									defaultShipViaControl.setValue(shipvia);
								}
								if (freightCostControl && freightcost) {
									freightCostControl.setValue(freightcost);
									this.updateShipping(freightcost);
								}
								if (commentsControl && comments) {
									commentsControl.setValue(comments);
								}

								if (refControl && header?.customerref) {

									refControl.setValue(header.customerref);
								}

								if (notesControl && notes) {
									notesControl.setValue(notes);
								}

								this.cart_items = r.cart;

							}
						});

					}
				}

				this.updateTotals();
			});
		}
	}

	getItemDiscountMultiplier(item) {
		if (!isNaN(this.financial(item.discount))) {
			return this.financial(item.discount / 100);
		}
		return 0;
	}



	editOrder(orderno: any) {
		this.sending = false;	
		this.orderResults = false;
		this.orderService.loadSalesOrder(orderno).subscribe((results: any) => {

			this.editing_order = true;
			this.order_details = results;
			this.adjustment.additionalcharge = this.order_details.header.additionalcharge;
			this.adjustment.additionaltext = this.order_details.header.additionaltext;
			this.customer.preselected = results.preselected;

			this.fetchingCart = this.orderService.getCart(results.header.debtorno, results.header.branchcode).subscribe(async (items: any) => {

				this.editing_order = true;
				this.order_details = results;

				this.cart_items = items;
				this.customer.editingorder = results.header
				this.customer.preselected = results.preselected;

				if (items.length) {

					if (!items[0].override || items[0].override == '') {
						//order was not flagged - do not stop it
						this.proceed = true;
						this.allowed_credit = true;
						this.overcredit_override = items[0].override
					}
				}

				this.updateTotals();
				this.updatePayments(false);
			});

			this.flip = 'active';
		})
	}

	//deposits
	updatePayments(event: any) {

		if (event) {
			this.payments_added = event;
			this.payment_total = this.payments_added.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0);
		}

		const data = {
			orderno: (this.order_details) ? this.order_details.header.orderno : '1',
			debtorno: this.customer.debtorno,
			branchcode: this.customer.branchcode,
		}

		this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
			this.payments = results;
			this.updateTotals()

			this.payment_total = this.financial(results.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0));

		});

	}

	financial(num: number): number {
		const rounded = Math.round((num + Number.EPSILON) * 100) / 100; // Rounding to 2 decimal places again
		return parseFloat(rounded.toFixed(2));
	}



	openPreOrder() {
		this.modalService.open(this.preorderRef, { ariaLabelledBy: 'modal-title', size: 'l' }).result.then((result: any) => {}, (reason) => {

		});
	}

	placePreOrder(cont: boolean) {
		this.modalService.dismissAll();
		if (cont) {

			let preorder = {
				status: true,
				date: this.preorderDate.value ?? new Date()
			};

			this.orderService.createPreOrder(this.customer_form.value, this.cart_items, this.cart_totals, this.user, preorder)
				.subscribe((results: any) => {
					this.sending = false;
					this.orderResults = results;
					this.flip = 'inactive';
					this.orderDetails = results;
					this.reloadCustomer.emit(results);
					this.editing_order = false;
					this.customer.preselected = false;

					this.orderService.clearOrder(this.customer.debtorno, this.customer.branchcode)
						.subscribe(() => {
							this.cart_items = [];
							this.total_cart_qty = 0;
							let data = {
								customerdata: this.customer_form.value,
								editing: false,
								neworder: true,
								orderdetails: results,
								user: this.user
							};

							this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, () => {});
							// this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
						});
				})
		};
	}

	selectCustomer(customer: any) {

		const data = { to: customer, from: this.customer }
		this.orderService.changeOrderCustomer(data).subscribe((results: any) => {
			this.modalService.dismissAll();
			this.customer_updated.emit(customer);
			this.router.navigate(['./orders/entry/' + customer.debtorno + '/' + customer.branchcode]);
		});
	}

	openChangeCustomer() {
		this.modalService.open(this.changeCustomerRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
	}

	getCustomerNotes() {
		if (this.customer?.debtorno) {
			const data = {
				debtorno: this.customer.debtorno,
				branch: this.customer.branchcode
			};

			if (this.customerNotesSubscription) {
				this.customerNotesSubscription.unsubscribe();
			}

			this.customerNotesSubscription = this.customerService.getCustomerNotes(data).pipe(
				take(1)
			).subscribe(notes => {
				this.custNotes = notes.filter(note => note.note_code.toUpperCase() === "ORDER");
			});
		}
	}
	editNote(note: any) {
		note.isEditing = !note.isEditing;
	}

	deleteNote(note: any) {
		this.custNotes = this.custNotes.filter(n => n !== note);
	}

	validateDate(event: any) {
		let newDate = new Date(event);
		let now = new Date();

		// Create new dates with only the year, month, and day components
		let formattedNow = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		let formattedNew = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());

		if (formattedNow.getTime() <= formattedNew.getTime()) {
			this.preorderDate.value = newDate;
			this.preorderDate.status = true;
		} else {
			this.preorderDate.value = "Pre-Order date has to be a future date.";
			this.preorderDate.status = false;
		}
	}

	openTrackingNumberAssign(){
		this.modalService.open(this.assignTrackingRef, { ariaLabelledBy: 'modal-title', size: 'lg', backdrop: 'static' }).result.then((result) => {}, (reason) => {

		});
	}

	ngOnDestroy() {
		if (this.worker) {
			this.worker.terminate();
		}

		//this.resetOrder();
	}

	showLocQoh(locations: any): number | undefined {
		if (Array.isArray(locations) && locations.length) {
			const loc = locations.find((l) => l?.loccode === this.user?.defaultlocation?.loccode);
			return loc?.quantity;
		}
		return 0;
	}

} 